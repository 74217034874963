export default {
    methods: {
        imageSource(img) {
            let returnImage = img;
            if (img === null || img === "null" || img === "") {
              returnImage =
                "https://ingomucdn.s3.amazonaws.com/image/png/user-blank.png";
            }
            return returnImage;
          },
          companyImageSource(img) {
            let returnImage = img;
            if (img === null || img === "null" || img === "") {
              returnImage =
                "https://ingomucdn.s3.amazonaws.com/image/png/ingomu_logo-2.png";
            }
            return returnImage;
          },            
    }
}