var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column-fluid"},[_c('div',{staticClass:"container-fluid py-8"},[_c('div',{staticClass:"d-flex flex-row"},[_c('UserNav',{attrs:{"activePage":"coaches","activeTab":"3"}}),_c('div',{staticClass:"flex-row-fluid ml-lg-8"},[_c('div',{staticClass:"flex-row-fluid"},[_c('div',[_c('div',{staticClass:"card card-custom card-stretch gutter-b"},[_c('div',{staticClass:"card-body pt-8 pb-0"},[_c('div',{staticClass:"table-responsive"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterValue),expression:"filterValue"}],staticClass:"form-control mb-4",attrs:{"type":"text","placeholder":"Search coaches"},domProps:{"value":(_vm.filterValue)},on:{"input":function($event){if($event.target.composing)return;_vm.filterValue=$event.target.value}}}),_vm._m(0),_c('b-container',{attrs:{"fluid":""}},[_c('b-table',{staticClass:"table table-borderless table-vertical-center",attrs:{"id":"my-table","items":_vm.filteredCoaches,"fields":_vm.fields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(image)",fn:function(data){return [_c('div',{staticClass:"symbol symbol-50 symbol-light mr-1"},[_c('span',{staticClass:"symbol-label"},[_c('img',{staticClass:"h-100 br-42 align-self-center",attrs:{"src":_vm.imageSource(data.item.user.profileimage),"alt":""}})]),(data.item.isTest)?_c('i',{staticClass:"symbol-badge bg-danger"}):_c('i',{staticClass:"symbol-badge bg-success"})])]}},{key:"cell(email)",fn:function(data){return [_c('router-link',{staticClass:"text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg",attrs:{"to":{
                          name: 'coachdetail',
                          params: {
                            coachId: data.item.coach_list[0].coach_list_id,
                          },
                        }}},[_vm._v(" "+_vm._s(data.item.user.firstname)+" "+_vm._s(data.item.user.lastname)+" ")]),_c('div',[_c('a',{staticClass:"text-dark font-weight-bold text-hover-primary"},[_vm._v(" "+_vm._s(data.item.email)+" ")])])]}},{key:"cell(language)",fn:function(data){return [_c('span',{staticClass:"text-dark font-weight-bold"},[_vm._v(" "+_vm._s(_vm.coachTitle(data.item))+" ")]),_c('div',{staticClass:"font-weight-500 text-hover-primary"},[_vm._v(" "+_vm._s(_vm.livestreamTimes(data.item))+" ")]),_c('div',{staticClass:"text-dark font-weight-bold text-hover-primary"},[_vm._v(" "+_vm._s(data.item.category)+" ")])]}},{key:"cell(buttons)",fn:function(data){return [_c('router-link',{staticClass:"btn btn-hover-bg-primary btn-text-primary btn-hover-text-white border-0 font-weight-bold py-4",attrs:{"to":{
                          name: 'coachdetail',
                          params: {
                            coachId: data.item.coach_list[0].coach_list_id,
                          },
                        }}},[_vm._v(" Details ")])]}},{key:"emptyfiltered",fn:function(scope){return [_c('h4',{staticClass:"text-center"},[_vm._v(" There are no coaches that match that criteria. ")])]}}])})],1)],1)])])])])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',{staticClass:"font-weight-500 text-hover-primary mb-2"},[_c('span',{staticClass:"dot-success"}),_vm._v("  A green dot with a coach name means the coach is available on the App. ")]),_c('li',{staticClass:"font-weight-500 text-hover-primary"},[_c('span',{staticClass:"dot-danger"}),_vm._v("  A red dot means that the coach is onboarding or away from the app and not available. ")])])
}]

export { render, staticRenderFns }