<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container-fluid py-8">
      <div class="d-flex flex-row">
        <UserNav activePage="coaches" activeTab="3"></UserNav>
        <!--begin::Content-->
        <div class="flex-row-fluid ml-lg-8">
          <!--begin::Content-->
          <div class="flex-row-fluid ">
            <div>
              <!--begin::Advance Table Widget 2-->
              <div class="card card-custom card-stretch gutter-b">
                <!--begin::Body-->
                <div class="card-body pt-8 pb-0">
                  <!--begin::Table-->
                  <div class="table-responsive">
                    <input type="text" v-model="filterValue" class="form-control mb-4" placeholder="Search coaches" />
                    <ul>
                      <li class="font-weight-500 text-hover-primary mb-2">
                        <span class="dot-success"></span>&nbsp;&nbsp;A green dot with a coach name means the coach is
                        available on the App.
                      </li>
                      <li class="font-weight-500 text-hover-primary">
                        <span class="dot-danger"></span>&nbsp;&nbsp;A red dot means that the coach is onboarding or away
                        from
                        the app and not available.
                      </li>
                    </ul>
                    <b-container fluid>
                      <b-table id="my-table" :items="filteredCoaches" :fields="fields"
                        class="table table-borderless table-vertical-center" show-empty>
                        <template #cell(image)="data">
                          <div class="symbol symbol-50 symbol-light mr-1">
                            <span class="symbol-label">
                              <img :src="imageSource(data.item.user.profileimage)" class="h-100 br-42 align-self-center"
                                alt="" />
                            </span>
                            <i v-if="data.item.isTest" class="symbol-badge bg-danger"></i>
                            <i v-else class="symbol-badge bg-success"></i>
                          </div>
                        </template>
                        <template #cell(email)="data">
                          <router-link :to="{
                            name: 'coachdetail',
                            params: {
                              coachId: data.item.coach_list[0].coach_list_id,
                            },
                          }" class="
                                text-dark
                                font-weight-bolder
                                text-hover-primary
                                mb-1
                                font-size-lg
                              ">
                            {{ data.item.user.firstname }} {{ data.item.user.lastname }}
                          </router-link>
                          <div>
                            <a class="text-dark font-weight-bold text-hover-primary">
                              {{ data.item.email }}
                            </a>
                          </div>
                        </template>
                        <template #cell(language)="data">
                          <span class="text-dark font-weight-bold">
                            {{ coachTitle(data.item) }}
                          </span>
                          <div class="font-weight-500 text-hover-primary">
                            {{ livestreamTimes(data.item) }}
                          </div>
                          <div class="text-dark font-weight-bold text-hover-primary">
                            {{ data.item.category }}
                          </div>
                        </template>
                        <template #cell(buttons)="data">
                          <router-link :to="{
                            name: 'coachdetail',
                            params: {
                              coachId: data.item.coach_list[0].coach_list_id,
                            },
                          }" class="
                                btn
                                btn-hover-bg-primary
                                btn-text-primary
                                btn-hover-text-white
                                border-0
                                font-weight-bold
                                py-4
                              ">
                            Details
                          </router-link>
                        </template>
                        <template #emptyfiltered="scope">
                          <h4 class="text-center">
                            There are no coaches that match that criteria.
                          </h4>
                        </template>
                      </b-table>
                    </b-container>
                  </div>
                  <!--end::Table-->
                </div>
                <!--end::Body-->
                <!--end::Advance Table Widget 2-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import defaultProfileImageMixin from "@/mixins/defaultProfileImage";
import UserNav from "@/view/layout/header/UserNav.vue";

export default {
  name: "coaches",
  components: {
    UserNav
  },
  metaInfo() {
    return {
      title: "Coaches",
    };
  },
  mixins: [defaultProfileImageMixin],
  data() {
    return {
      fields: [
        { key: "image", label: "" },
        { key: "email", label: "Name" },
        { key: "language", label: "Learning Community" },
        { key: "buttons", label: "" },
      ],
      filterValue: null,
      activeTab: "All",
      filteredCoaches: [],
    };
  },
  methods: {
    coachTitle(item) {
      let titleArray = [];
      item.coach_list.forEach((element) => {
        titleArray.push(element.title);
      });
      return titleArray.join();
    },
    livestreamTimes(item) {
      let timeArray = [];
      item.coach_list.forEach((element) => {
        timeArray.push(element.livestream_times);
      });
      return timeArray.join();
    },
  },
  mounted() {
    this.$store.dispatch("getProgramCoachList");
    this.$store.dispatch("getCoachCategories");
    this.$store.commit("CHANGE_TABINDEX", { tabIndex: 2 });
    this.$store.commit("CHANGE_ACTIVETAB", { activeTab: 2 });
    this.$store.dispatch("addTopicsToUsers");
  },
  watch: {
    programCoaches(value) {
      this.filteredCoaches = this.programCoaches;
    },
    filterValue(newValue, oldValue) {
      this.filteredCoaches = []
      if (newValue == "" || newValue == null) {
        this.filteredCoaches = this.programCoaches;
      } else {
        const newCoachList = [];
        this.programCoaches.forEach((element) => {
          let fullName = element.user.firstname + " " + element.user.lastname;
          let keywordArray = []
          if (element.keywords != null) {
            keywordArray = element.keywords
          }
          if (
            element.category.toLowerCase().includes(newValue.toLowerCase()) ||
            element.email.toLowerCase().includes(newValue.toLowerCase()) ||
            element.coach_list[0].title.toLowerCase().includes(newValue.toLowerCase()) ||
            element.coach_list[0].description
              .toLowerCase()
              .includes(newValue.toLowerCase()) ||
            element.user.firstname.toLowerCase().includes(newValue.toLowerCase()) ||
            element.user.lastname.toLowerCase().includes(newValue.toLowerCase()) ||
            fullName.toLowerCase().includes(newValue.toLowerCase()) ||
            keywordArray.includes(newValue.toLowerCase())
          ) {
            newCoachList.push(element);
          }
          this.filteredCoaches = newCoachList;
        });
      }
    },
  },
  computed: {
    ...mapGetters(["programCoaches", "programMoreCoaches", "categories"]),
  },
};
</script>

<style scoped>
.dot-success {
  height: 12px;
  width: 12px;
  background-color: #1BC5BD !important;
  border-radius: 50%;
  display: inline-block;
}

.dot-danger {
  height: 12px;
  width: 12px;
  background-color: #F64E60 !important;
  border-radius: 50%;
  display: inline-block;
}
</style>